<template>
    <div>
        <PageTitle title="身份注册" height="8rem"></PageTitle>
        <div style="position: relative;top: -3rem;width: 85%;margin: 0 auto;">
            <List :width="100">
                <ListItem>
                    <template v-slot:left>
                        <img src="../assets/personal.png" style="width: 50px;"/>
                    </template>
                    <template v-slot:right>
                        <h4>用户，您好！</h4>
                        <p>请确认个人信息是否有误</p>
                    </template>
                </ListItem>

                <ListItem>
                    <template v-slot:top>
                        <van-form ref="identityInfoForm" label-width="50px" colon>
                            <van-field
                                    name="userName"
                                    v-model="postData.userName"
                                    label="姓名"
                                    placeholder="请输入姓名"
                                    :rules="[{ required: true }]"
                            />
                            <van-field
                                    name="userNum"
                                    v-model="postData.userNum"
                                    label="证件号"
                                    placeholder="请输入身份证号码"
                                    :rules="[{ required: true }, { validator, message: '请输入正确的身份证号码' }]"
                                    v-on:keyup="getSexBirthByuserNum"
                                    @blur="getSexBirthByuserNum"
                                    @click="getSexBirthByuserNum"
                            />
                            <van-field
                                    readonly
                                    clickable
                                    name="userSex"
                                    :value="postData.userSex"
                                    label="性别"
                                    placeholder="点击选择性别"
                                    @click="sexShowPicker = true"
                                    :rules="[{ required: true }]"
                            />
                            <van-popup v-model="sexShowPicker" position="bottom">
                                <van-picker
                                        show-toolbar
                                        :columns="sexColumns"
                                        @confirm="onConfirmSex"
                                        @cancel="sexShowPicker = false"
                                />
                            </van-popup>
                            <van-field
                                    readonly
                                    clickable
                                    name="userMz"
                                    :value="postData.userMz"
                                    label="民族"
                                    placeholder="点击选择民族"
                                    @click="nationShowPicker = true"
                                    :rules="[{ required: true }]"
                            />
                            <van-popup v-model="nationShowPicker" position="bottom">
                                <van-picker
                                        show-toolbar
                                        :columns="nationColumns"
                                        @confirm="onConfirmNation"
                                        @cancel="nationShowPicker = false"
                                />
                            </van-popup>
                            <van-field
                                    readonly
                                    name="userBirth"
                                    v-model="postData.userBirth"
                                    label="出生"
                                    placeholder="请输入出生日期"
                                    @click="birthShowPicker = true"
                                    :rules="[{ required: true }]"
                            />
                            <van-popup v-model="birthShowPicker" position="bottom">
                                <van-datetime-picker
                                        type="date"
                                        :min-date="minDate"
                                        :max-date="maxDate"
                                        @confirm="onConfirmBirth"
                                        @cancel="birthShowPicker = false"
                                />
                            </van-popup>
                            <van-field
                                    name="userAddr"
                                    v-model="postData.userAddr"
                                    label="住址"
                                    placeholder="请输入住址"
                                    :rules="[{ required: true }]"
                            />
                        </van-form>
                    </template>
                </ListItem>
            </List>
            <van-button type="primary" block @click="checkInfo()">信息确认</van-button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';
    import {checkID} from '@/api/idCardVerification.js';
    import {AddCardInfo, QueryAccount, AddAccountList} from '@/api/user.js';

    export default {
        name: '',
        components: {
            PageTitle,
            List,
            ListItem,
        },
        data(){
            return {
                sexColumns: ['男', '女'],
                sexShowPicker: false,

                nationColumns: [
                    "汉族", "壮族", "满族", "回族", "苗族", "维吾尔族", "土家族", "彝族", "蒙古族", "藏族", "布依族", "侗族", "瑶族", "朝鲜族", "白族", "哈尼族",
                    "哈萨克族", "黎族", "傣族", "畲族", "傈僳族", "仡佬族", "东乡族", "高山族", "拉祜族", "水族", "佤族", "纳西族", "羌族", "土族", "仫佬族", "锡伯族",
                    "柯尔克孜族", "达斡尔族", "景颇族", "毛南族", "撒拉族", "布朗族", "塔吉克族", "阿昌族", "普米族", "鄂温克族", "怒族", "京族", "基诺族", "德昂族", "保安族",
                    "俄罗斯族", "裕固族", "乌孜别克族", "门巴族", "鄂伦春族", "独龙族", "塔塔尔族", "赫哲族", "珞巴族"
                ],
                nationShowPicker: false,

                birthShowPicker: false,
                minDate: new Date(1900, 0, 1),
                maxDate: new Date(),

                postData: {
                    userName: "",
                    userNum: "",
                    userAddr: "",
                    userMz: "",
                    userSex: "",
                    userBirth: "",
                }
            }
        },
        mounted: function () {
            if (this.$store.getters.temp != undefined) {
                this.postData = this.$store.getters.temp;
                if (this.postData.userBirth.length == 8) {
                    this.postData.userBirth = this.$util.moment(this.postData.userBirth).format('YYYY-MM-DD');
                }
            }
        },
        methods: {
            validator(val) {
                return checkID(val);
            },
            onConfirmSex(value) {
                this.postData.userSex = value;
                this.sexShowPicker = false;
            },
            onConfirmNation(value) {
                this.postData.userMz = value;
                this.nationShowPicker = false;
            },
            onConfirmBirth(time) {
                this.postData.userBirth = this.$util.moment(time).format('YYYY-MM-DD');
                this.birthShowPicker = false;
            },
            getSexBirthByuserNum() {
                if (this.postData.userNum.length == 18) {
                    this.postData.userNum = this.postData.userNum.toUpperCase();
                    this.$refs.identityInfoForm.validate('userNum').then(
                        () => {
                            let birthday = "";
                            if (this.postData.userNum.length == 18) {
                                this.postData.userSex = (this.postData.userNum.charAt(16) % 2 == 0 ? "女" : "男");
                                birthday = this.postData.userNum.substr(6, 8);
                                this.postData.userBirth = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                            }
                        },
                        () => {
                            this.postData.userBirth = "";
                            this.postData.userSex = "";
                        }
                    )
                }
            },
            checkInfo(){
                this.$refs.identityInfoForm.validate().then(
                    () => {
                        //提交表单
                        AddCardInfo(this.postData).then(
                            (res) => {
                                QueryAccount(2, this.postData.userNum, this.$store.getters.userInfo.UserId).then(
                                    (result) => {
                                        // let userNumber = this.postData.userNum;
                                        let userId = (this.$store.getters.userInfo.UserId);
                                        let phoneNumber = (this.$store.getters.userInfo.PhoneNumber);
                                        // let arrayUserNum = [];
                                        let addList = [];//点击操作已有账号，添加数据
                                        for (let item of result) {
                                            if (item.id == 0) {
                                                item.id = userId;
                                                item.sfzh = this.postData.userNum;
                                                item.address = this.postData.userAddr;
                                                item.brxm = this.postData.userName;
                                                item.sjhm = phoneNumber;
                                                addList.push(item);
                                            }
                                            // arrayUserNum.push(item.sfzh);
                                        }
                                        // if (arrayUserNum.length == result.length) {
                                            localStorage.setItem('IdCardInfo',JSON.stringify(this.postData));
                                            if (res == 1) {
                                                this.$dialog.confirm({
                                                    title: '温馨提醒',
                                                    message: '该身份证号码已有对应就诊卡，请确定是否继续注册新账号',
                                                    confirmButtonText: '操作已有账号',
                                                    cancelButtonText: '继续'
                                                }).then(() => {
                                                    // on confirm
                                                    AddAccountList(addList).then(
                                                        () => {
                                                            this.$router.push('/accountJudgment/0/0/0');
                                                        },
                                                        () => {
                                                        }
                                                    );
                                                }).catch(() => {
                                                    // on cancel
                                                    this.$router.push('/medicalRecordUpload');
                                                });
                                            }
                                            else {
                                                this.$router.push('/medicalRecordUpload');
                                            }
                                        // }
                                    },
                                    () => {
                                    }
                                );
                            },
                            () => {
                            }
                        );
                    },
                    () => {
                        //校验不通过
                    }
                )
            }
        }
    }
</script>

<style scoped="" lang="less">
    @import "../style/var.less";

    .top p {
        text-align: center;
        margin: 0;
        padding: 10px 0 0;
    }

    .right h4 {
        margin: 15px 0 5px;
    }

    .left p, .right p {
        font-size: @font-size-md;
        margin: 5px 0 15px;
    }
</style>
